<template>
  <section v-if="factBox?.length" class="mb-30 md:mb-50">
    <div class="bg-grey-400 px-25 pt-30 pb-40 text-white">
      <div class="text-22 font-khand leading-32 font-bold mb-10">VIPedia</div>
      <template v-for="(fact, index) in factBox">
        <template v-for="(value, prop) in fact" :key="'fact' + index">
          <div v-if="value" class="mb-10 last:mb-0 lg:mb-10 text-16">
            <strong class="font-bold">{{ prop }}: </strong>
            <span>{{ value }}</span>
          </div>
        </template>
      </template>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  personData: {
    type: Object as PropType<Aller.TagPerson>,
    required: false,
    default: null,
  },
});

const data = props.personData;

const { children, siblings, parents, spouse, otherRelations } = useTagPerson(data);

const factBox = !!data ? [
  getFactItem('Navn', [data.givenName, data.familyName].filter(Boolean).join(' '), [data.givenName, data.familyName]),
  getFactItem('Kendt som', data.additionalName, [data.additionalName]),
  getFactItem('Medvirker i', data.performerIn?.join(', '), [data.performerIn?.length > 0]),
  getFactItem('Født', getReadableBirthDate(), [data.birthDate]),
  getFactItem('Beskæftigelse', data.jobTitle, [data.jobTitle]),
  getFactItem('Død', `${getReadableDeathDate()} `, [data.deathDate]),
  getFactItem('By', data.homeLocation, [data.homeLocation]),
  getFactItem('Forældre', parents?.map((p) => p.name)?.join(', '), [parents?.length > 0]),
  getFactItem('Søskende', siblings?.map((s) => s.name)?.join(', '), [siblings?.length > 0]),
  getFactItem('Børn', children?.map((c) => c.name)?.join(', '), [children?.length > 0]),
  getFactItem('Ægtefælle', spouse?.name, [spouse?.name]),
  getFactItem('Kender', otherRelations?.map((r) => r.name)?.join(', '), [otherRelations?.length > 0]),
].filter(Boolean) : null;

function getFactItem(key: string, value: any, checkValues: any[]) {
  if (checkValues.some(Boolean)) {
    return { [key]: value };
  }
  return null;
}

function getDateFormatted(date: string) {
  return new Date(date).toLocaleString('da', { day: 'numeric', month: 'long', year: 'numeric' });
}

function getReadableBirthDate() {
  const dateFormatted = getDateFormatted(props.personData.birthDate);
  return !!props.personData.deathDate ? dateFormatted : `${dateFormatted} (${getAge()} år)`;
}

function getReadableDeathDate() {
  return `${getDateFormatted(props.personData.deathDate)} (${getAge()} år)`;
}

function getAge() {
  const endDate = props.personData.deathDate ? new Date(props.personData.deathDate) : new Date();
  const startDate = new Date(props.personData.birthDate);
  return endDate.getFullYear() - startDate.getFullYear();
}
</script>