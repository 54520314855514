<template>
  <main id="tag-id">
    <section class="mb-40 md:mb-30">
      <WidthWrapper wrapper-classes="max-w-780 mx-auto pt-20 md:pt-50 px-20 lg:px-0">
        <h1 class="font-khand text-42 leading-46 md:text-50 md:leading-50 font-bold">{{ apiData.data.attributes.name }}</h1>
        <p v-if="personData?.additionalName" class="font-khand font-bold text-26 -mt-2">({{ personData.additionalName }})</p>
        <p v-if="personData?.jobTitle" class="font-bold text-20 leading-29">{{ personData?.jobTitle }}</p>
        <div v-if="apiData.data?.attributes?.teaser || personData?.image" class="md:flex md:gap-25 md:mt-30">
          <div v-if="apiData.data?.attributes?.teaser" v-html="apiData.data?.attributes?.teaser" class="text-20 leading-29 mt-15 md:mt-0 md:flex-1 md:shrink-0" />
          <div v-if="personData?.image" class="md:flex-1 md:shrink-0 mt-25 md:mt-0 -mx-20 md:mx-0">
            <AllerImgixImg class="w-full" :media="personData?.image" :modifiers="{ ar: '16:9' }" sizes="(max-width: 768px) 100vw, 720px" :render-widths="[300, 360, 380, 394, 410, 600, 720, 1020, 1080, 1280, 1440]" />
          </div>
        </div>
      </WidthWrapper>
    </section>
    <WidthWrapper wrapper-classes="max-w-780 mx-auto px-20 lg:px-0">
      <PersonTagFactBoxSection v-if="!!personData" :person-data="personData" />
      <section v-if="includedTeasers" class="mb-40">
        <h2 class="font-khand mb-10 font-bold text-29 leading-44">Nyeste artikler om <span class="capitalize">{{ apiData.data.attributes.name }}</span></h2>
        <div class="grid grid-cols-6 gap-x-20 md:gap-y-60">
          <template v-for="(teaser, index) in includedTeasers.slice(0, teasersToShow)">
            <TeaserAside3 v-if="teaser" lazy-img="eager" class="col-span-6 md:col-span-2" :teaser="teaser" :key="teaser.id + index" />
          </template>
        </div>
        <LoadMore :initial-count="teasersToShow" :api-url="apiData.links.related" :total-count="apiData.data?.attributes?.articleCount" />
      </section>
      <section v-if="apiData.data.attributes.contentComponents" class="mb-40">
        <LazyContentComponents :content-components="apiData.data.attributes.contentComponents" />
      </section>
    </WidthWrapper>
    <LazyGlobals />
  </main>
</template>

<script setup lang="ts">
const apiData = inject('apiData') as Aller.Category;
const { includedTeasers } = useAllerRelationships(apiData);

const personData: Aller.TagPerson | null = apiData.data.attributes.schemaPerson || null;

const teasersToShow = !!personData ? 6 : 12;

if (!!personData) {
  useTagPerson(personData).setPersonJsonLd();
}
</script>
