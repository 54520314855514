export const useTagPerson = (person: Aller.TagPerson) => {
  const { public: config } = useRuntimeConfig();

  const personRelations: { type: string, name: string }[] = person?.relationships || [];
  const children = personRelations.filter((rel) => rel.type === 'child') || [];
  const siblings = personRelations.filter((rel) => rel.type === 'sibling') || [];
  const parents = personRelations.filter((rel) => rel.type === 'parent') || [];
  const otherRelations = personRelations.filter((rel) => rel.type === 'other') || [];
  const spouse = personRelations.find((rel) => rel.type === 'spouse') || null;

  const setPersonJsonLd = () => {
    const jsonLd = {
      "@context": "https://schema.org",
      "@type": "Person",
      "address": person.homeLocation || null,
      "jobTitle": person.jobTitle,
      "givenName": person.givenName,
      "additionalName": person.additionalName,
      "familyName": person.familyName,
      "birthDate": person.birthDate,
      "deathDate": person.deathDate,
      "nationality": person.nationality,
      "performerIn": person.performerIn.map((rel) => ({
        "@type": "Event",
        "name": rel,
      })),
      "relatedTo": otherRelations.map((rel) => ({
        "@type": "Person",
        "name": rel.name,
      })),
      "children": children.map((rel) => ({
        "@type": "Person",
        "name": rel.name,
      })),
      "siblings": siblings.map((rel) => ({
        "@type": "Person",
        "name": rel.name,
      })),
      "parents": parents.map((rel) => ({
        "@type": "Person",
        "name": rel.name,
      })),
      "spouse": spouse ? {
        "@type": "Person",
        "name": spouse.name,
      } : null,
      "sameAs": person?.sameAs?.includes('https') ? person.sameAs : `${config.siteUrl}${person.sameAs}`,
      "image": person.image?.imageUrl,
    }

    useHead({
      script: [
        {
          vmid: 'jsonld',
          id: 'jsonld',
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@graph': [jsonLd],
          }),
          tagPosition: 'bodyClose',
        },
      ],
    });
  }

  return {
    setPersonJsonLd,
    otherRelations,
    children,
    siblings,
    parents,
    spouse,
  };
}